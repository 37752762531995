import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  useTheme
} from "@material-ui/core";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useQueryClient } from "react-query";
import LocalizedLink from "../../../../../components/localized-link";
import { scrollToAnchor } from "../../../../../helpers/browser-behavior";
import {
  getLocaleFromCountryLang,
  isValidLocale,
  navigateToLocale
} from "../../../../../helpers/locale";
import { getProductDetailsPath } from "../../../../../helpers/url";
import useCountry from "../../../../../hooks/use-country";
import useCustomSnackbar from "../../../../../hooks/use-custom-snackbar";
import useIsAuthenticated from "../../../../../hooks/use-is-authenticated";
import useLang from "../../../../../hooks/use-lang";
import useLocalizedLocationPath from "../../../../../hooks/use-localized-location-path";
import useProductQuery from "../../../../../hooks/use-product-query";
import useTranslate from "../../../../../hooks/use-translate";
import ExpressDeliveryLogo from "../../../../express-delivery/ExpressDeliveryLogo";
import ProductDetailsContentModules from "../../../../shop/ProductDetails/components/content-modules";
import ProductDetailsOilTable from "../../../../shop/ProductDetails/components/Overview/OilTable";
import { generateOilDynamicContentModules } from "../../../../shop/ProductDetails/utils";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationLayout from "../../../components/Layout";
import { getFullWindowMinHeight } from "../../../components/Layout/utils";
import PodProductCard from "../../../components/PodProductCard";
import { ACTIVATION_BASE_URL } from "../../../constants";
import useActivationPath from "../../../hooks/useActivationPath";
import { usePodDetails } from "../../../hooks/usePodDetails";
import useUnlockAllowed from "../../../hooks/useUnlockAllowed";
import { saveToMyPods } from "../../../utils/pod-activation";
import { isPodActivationRequired } from "../../../utils/pod-flags";

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep1PodInfo({
  podKeyOrSerialNumber
}: Props) {
  const t = useTranslate();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const snackbar = useCustomSnackbar();
  const getLocalizedPath = useLocalizedLocationPath();
  const getActivationPath = useActivationPath();
  const isAuthenticated = useIsAuthenticated();
  const [isRedirecting, setRedirecting] = useState(false);

  // Pod details
  const { data: podDetails } = usePodDetails(podKeyOrSerialNumber);

  // Pod product variant
  const { data: podProduct, isLoading: isLoadingPodProduct } = useProductQuery({
    batchId: podDetails?.batchId
  });
  const podProductVariant = podProduct?.variants?.find(v =>
    v.batchIds.includes(podDetails?.batchId)
  );

  // Auto-redirect if pod region is not matching
  const country = useCountry();
  const lang = useLang();
  const podCountry = podDetails?.regionFlags?.regionCode;
  const podSubCountry = podDetails?.regionFlags?.subregionCode;

  // Redirect to coa pdf for Utah pods
  useEffect(() => {
    if (podCountry === "US" && podSubCountry === "UT" && podProduct) {
      const coaUrl = podProductVariant.variantDetail?.coaReport?.coaUrl;
      setRedirecting(true);
      navigate(coaUrl);
      return;
    }
  }, [podCountry, country, podProduct]);

  // Auto switch url country based on pod country
  useEffect(() => {
    if (podCountry && podCountry !== country) {
      let autoRedirectLocale = getLocaleFromCountryLang(podCountry, lang);
      if (!isValidLocale(autoRedirectLocale)) {
        autoRedirectLocale = getLocaleFromCountryLang(podCountry, "en"); // Fallback to english
      }
      if (!isValidLocale(autoRedirectLocale)) {
        return; // Else just abandon the autoredirect else it will 404
      }

      navigateToLocale(
        autoRedirectLocale,
        `${ACTIVATION_BASE_URL}/${podKeyOrSerialNumber}`
      );
    }
  }, [podCountry, country]);

  // Pod needs activation? account?
  let isActivationRequired = isPodActivationRequired(podDetails);
  if (!podDetails) {
    isActivationRequired = false; // Prevent issue with pods that dont have pod details errors
  }

  // Unlock permission
  const { isUnlockAllowed, isLoadingUnlockAllowed } =
    useUnlockAllowed(podKeyOrSerialNumber);

  // CTA link & state
  let ctaText = t("activation.step1PodInfo.cta");
  let ctaPath = `${podKeyOrSerialNumber}/register`;
  if (isUnlockAllowed) {
    ctaPath = `${podKeyOrSerialNumber}/activation`;
  }

  // Verify pod (No unlock)
  if (!isActivationRequired) {
    ctaText = t("activation.step1PodInfo.saveToMyPods");
    ctaPath = `${podKeyOrSerialNumber}/register`;
  }

  // Show loading while fetching pod details
  const showLoading = !podDetails || isLoadingPodProduct || isRedirecting;
  const showCtaLoading = isAuthenticated ? isLoadingUnlockAllowed : false;

  const [ctaInViewRef, ctaInView] = useInView({
    threshold: 0.75,
    initialInView: true
  });
  const showStickyCta = !ctaInView;

  const handleCtaClick = async event => {
    if (!isActivationRequired && isAuthenticated) {
      event.preventDefault();
      await saveToMyPods(podDetails.podSerialNumber);
      queryClient.invalidateQueries("user-activated-pod-history");
      navigate(getLocalizedPath("/user/pods"));
      snackbar.showSuccess(t("activation.step1PodInfo.saveToMyPods.success"));
    }
  };

  const handleLearnMoreClick = () => {
    scrollToAnchor("#pod-info-content-modules", -50);
  };

  // Main CTA
  const ctaContent = showCtaLoading ? (
    <CircularProgress size={16} color="secondary" />
  ) : (
    ctaText
  );

  return (
    <>
      <ActivationLayout showLoading={showLoading} paddingBottom={0}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight={{
            xs: getFullWindowMinHeight({ offset: 48, initial100vh: true }),
            sm: 0
          }}
        >
          {/* Pod details */}
          {/* Title & description */}

          <Box mb={{ xs: 2, sm: 2, md: 4 }}>
            <ActivationHeading>
              {isActivationRequired &&
                t("activation.step1PodInfo.title.readyToUnlock")}
              {!isActivationRequired &&
                t("activation.step1PodInfo.title.readyToUse")}
            </ActivationHeading>
          </Box>

          {/* Pod overview (Cover, title + brand, Oil stats table) */}
          <Box display="flex" flexDirection="column">
            {/* Pod info card */}
            <PodProductCard
              productVariant={podProductVariant}
              podKey={podDetails?.podKey}
            />

            {/* Pod title & brand */}
            <Box textAlign="center" mt={{ xs: 1.5, md: 3 }}>
              <Typography variant="h5" component="span">
                {podProductVariant?.title}
              </Typography>
            </Box>

            {/* Oil stats table */}
            {podProductVariant && (
              <Box mt={{ xs: 1.5, md: 3 }}>
                <ProductDetailsOilTable
                  product={podProduct}
                  price={false}
                  disableVariantsSelector
                />
              </Box>
            )}
          </Box>

          {/* Action buttons */}
          <Box mt={{ xs: 2, md: 3 }}>
            {/* Unlock actions (Unlock required) */}

            {/* Primary CTA */}

            {/* @ts-ignore */}
            <Button
              variant="contained"
              fullWidth
              color="primary"
              component={Link}
              to={getActivationPath(ctaPath)}
              onClick={handleCtaClick}
              disabled={showCtaLoading}
              ref={ctaInViewRef}
              style={{ visibility: showStickyCta ? "hidden" : "visible" }}
            >
              {ctaContent}
            </Button>

            {/* Shop with XPRESS button */}
            {isActivationRequired &&
              podProductVariant &&
              !podProduct.disablePdp && (
                <Box mt={{ xs: 1.5, md: 2 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    component={LocalizedLink}
                    to={getProductDetailsPath(
                      podProduct.productType,
                      podProduct.slug
                    )}
                    endIcon={<ExpressDeliveryLogo />}
                    data-analytics="activation.pod-info.shop-with-express"
                  >
                    {t("activation.shopWith")}
                  </Button>
                </Box>
              )}

            {/* Learn more about this pod */}
            {!isActivationRequired && (
              <Box mt={{ xs: 1.5, md: 2 }}>
                <Button
                  fullWidth
                  variant="contained"
                  endIcon={<ArrowDownwardRoundedIcon />}
                  onClick={handleLearnMoreClick}
                >
                  {t("activation.step1PodInfo.learnMore")}
                </Button>
              </Box>
            )}

            {/* Sticky Primary CTA */}
            <Box
              width="100%"
              position="fixed"
              top={{ xs: "unset", md: 0 }}
              bottom={{ xs: 0, md: "unset" }}
              left={0}
              padding={{ xs: 1.5, md: 2 }}
              zIndex={1200}
              bgcolor={alpha(theme.palette.grey[200], 0.7)}
              display="flex"
              justifyContent="center"
              style={{
                backdropFilter: "blur(4px)",
                WebkitBackdropFilter: "blur(4px)",
                opacity: showStickyCta ? 1 : 0,
                pointerEvents: showStickyCta ? "all" : "none",
                transition: "opacity 200ms"
              }}
            >
              <Container maxWidth="sm" disableGutters>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  component={Link}
                  to={getActivationPath(ctaPath)}
                  disabled={showCtaLoading}
                >
                  {ctaContent}
                </Button>
              </Container>
            </Box>
          </Box>
        </Box>
      </ActivationLayout>

      {/* Pod content modules */}
      {podProductVariant?.variantDetail?.coaReport && !showLoading && (
        <Box
          maxWidth={{
            xs: "none",
            sm: theme.breakpoints.values.sm,
            md: theme.breakpoints.values.md,
            lg: theme.breakpoints.values.md
          }}
          marginX="auto"
          marginY={{ xs: 6, sm: 6, md: 12 }}
        >
          <ProductDetailsContentModules
            id="pod-info-content-modules"
            contentModules={generateOilDynamicContentModules(podProductVariant)}
          />
        </Box>
      )}
    </>
  );
}
